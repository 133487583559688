import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Home" */'@/views/login')
  },
  {
    path: '',
    name:'Commun',
    component: () => import(/* webpackChunkName: "Commun" */ '@/components/Commun.vue'),

    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/accueil',
        name: 'Accueil',
        component: () => import(/* webpackChunkName: "Accueil" */'@/views/Accueil'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/factures',
        name: 'Factures',
        component: () => import(/* webpackChunkName: "Factures" */'@/views/Factures'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/vendeurs',
        name: 'Vendeurs',
        component: () => import(/* webpackChunkName: "Vendeurs" */'@/views/Vendeurs'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/gestion_stock',
        name: 'Gestion De Stock',
        component: () => import(/* webpackChunkName: "GestionStock" */'@/views/GestionStock'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/stock/history/:id',
        name: 'Historiques',
        component: () => import(/* webpackChunkName: "Historiques" */ '../views/Historiques'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/stock/:name',
        name: 'Stock',
        component: () => import(/* webpackChunkName: "Stock" */'@/components/Stock'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/factures/:id',
        name: 'EditItem',
        component: () => import(/* webpackChunkName: "Factures" */'@/components/EditItem'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/facture/:id',
        name: 'ImprimerFacture',
        component: () => import(/* webpackChunkName: "ImprimerFacture" */ '../components/imprimer_facture'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/recu/:id',
        name: 'recu',
        component: () => import(/* webpackChunkName: "recu" */ '../components/recu'),
        meta: {
          requiresAuth: true
        },
      }
  ]
  }


]

const router = new VueRouter({
  mode: 'history',
  routes

})

export default router
