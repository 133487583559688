import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import VueChartJS from 'vue-chartjs'
import axios from 'axios'
import VueAlertify from 'vue-alertify'
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

Vue.config.productionTip = false
Vue.use(VueChartJS)
Vue.use(VueAlertify)

Vue.prototype.$http =  axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'http://admin.prettylinge.com/api/',
  headers: { "Content-Type": "application/json"}
});


const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token

}
var is_admin = Vue.prototype.$is_admin
new Vue({
  router,
  is_admin,
  render: h => h(App)
}).$mount('#app')
